import React from "react";

import { InputLabel, InputLabelProps, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        display: "inline-block",
        fontSize: "14px",
        fontWeight: 500,
        padding: "0 16px",
        color: theme.palette.secondary.main,
    },
}));

export const Label = (props: InputLabelProps) => {
    const { className, ...otherProps } = props;

    const classes = useStyles();

    return (
        <InputLabel
            className={className ? `${classes.root} ${className}` : classes.root}
            {...otherProps}
        />
    );
};
