import * as firebase from "firebase/app";
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";

firebase.initializeApp({
    apiKey: "AIzaSyBoVjHjTx5LyDQ1SqVsggTWnbNklhzY7N8",
    authDomain: "sommer-og-fritid.firebaseapp.com",
    databaseURL: "https://sommer-og-fritid.firebaseio.com",
    projectId: "sommer-og-fritid",
    storageBucket: "sommer-og-fritid.appspot.com",
    messagingSenderId: "838002229339",
    appId: "1:838002229339:web:1022f335e29eb0201a7f7e",
});

firebase.analytics();
