import { useCallback, useMemo, useRef, useState } from "react";

import { Collapse, makeStyles } from "@material-ui/core";

import { NeutralAction } from "../components/card/NeutralAction";
import { PositiveAction } from "../components/card/PositiveAction";
import { Dialog, DialogActions, DialogContent, DialogHeader, useDialog } from "../components/dialog";
import { Label } from "../components/form/Label";
import { Group } from "../components/form/Group";
import { AlternativeHint } from "../components/form/Hint";
import { validateMandatory } from "../components/validation/validation";
import { useAnimation } from "../components/validation/animation.hook";
import { validateEmail } from "../components/validation/validation";
import { EmailField } from "../components/form";

export interface ResetPasswordDialogViewModel {
    email: string;
    emailDirty: boolean;
}

export interface ResetPasswordDialogErrorModel {
    any: boolean;
    email: string | undefined;
}

const useStyles = makeStyles({
    text: {
        fontSize: "16px",
        fontWeight: 400,
        color: "#4A4A4A",
        marginTop: 0,
    },
});

export interface ResetPasswordDialogProps {
    initialViewModel: ResetPasswordDialogViewModel;
    onConfirm: (viewModel: ResetPasswordDialogViewModel) => void;
    onCancel: () => void;
}

export const ResetPasswordDialog = (props: ResetPasswordDialogProps) => {
    const classes = useStyles();
    const { initialViewModel, onConfirm, onCancel } = props;
    const [viewModel, setViewModel] = useState(initialViewModel);

    const errorModel = useMemo<ResetPasswordDialogErrorModel>(() => {
        const emailError = [validateMandatory(viewModel.email), validateEmail(viewModel.email)].find(Boolean);

        return {
            any: Boolean(emailError),
            email: emailError,
        };
    }, [viewModel]);

    const changeEmail = useCallback((value: string) => {
        setViewModel(viewModel => ({ ...viewModel, email: value }));
    }, [setViewModel])

    const markEmailDirty = useCallback(() => {
        setViewModel(viewModel => ({ ...viewModel, emailDirty: true }));
    }, [setViewModel]);

    const [validationAnimationDuration, skipValidationAnimation] = useAnimation();

    const scrollToFirstError = useCallback(() => {
        const sectionId = (() => {
            if ( errorModel.email ) return "email-section";

            return undefined;
        })();

        if ( !sectionId ) return;

        const section = document.getElementById(sectionId);
        if ( !section ) return;

        section.scrollIntoView({ behavior: "smooth" });
    }, [errorModel]);

    const confirm = useCallback(() => {
        if ( document.activeElement ) {
            (document.activeElement as HTMLInputElement).blur();
        }

        if ( errorModel.any ) {
            skipValidationAnimation();
            setViewModel(viewModel => ({
                ...viewModel,
                emailDirty: true,
            }));

            return setTimeout(scrollToFirstError, 100);
        }

        onConfirm(viewModel);
    }, [skipValidationAnimation, scrollToFirstError, onConfirm, errorModel.any, viewModel]);

    return (
        <Dialog>
            <DialogHeader>Nulstil adgangskode</DialogHeader>

            <DialogContent>

                <p className={classes.text}>Hvis der findes en bruger i systemet med email-adressen, sender vi et link til nulstilling af adgangskode.</p>

                <div id="email-section">
                    <Label htmlFor="reset-password-email" style={{ marginBottom: "8px" }}>Email</Label>
                    <Group error={Boolean(viewModel.emailDirty && errorModel.email)}>
                        <EmailField id="reset-password-email" value={viewModel.email} onChange={changeEmail} onBlur={markEmailDirty} />

                        <Collapse in={Boolean(viewModel.emailDirty && errorModel.email)} timeout={validationAnimationDuration}>
                            <AlternativeHint message={errorModel.email} />
                        </Collapse>
                    </Group>
                </div>

            </DialogContent>

            <DialogActions>
                <NeutralAction onClick={onCancel}>Annuller</NeutralAction>
                <PositiveAction onClick={confirm}>Bekræft</PositiveAction>
            </DialogActions>

        </Dialog>
    );
};

export const useResetPasswordDialog = (onConfirm: ResetPasswordDialogProps["onConfirm"], initialViewModel?: ResetPasswordDialogViewModel) => {
    const { openDialog, closeDialog } = useDialog();

    const hasConfirmed = useRef(false);
    const confirm: ResetPasswordDialogProps["onConfirm"] = useCallback(data => {
        if ( hasConfirmed.current ) return;
        hasConfirmed.current = true;

        closeDialog();

        onConfirm(data);
    }, [closeDialog, onConfirm, hasConfirmed]);

    const viewModel = useRef<ResetPasswordDialogViewModel>(initialViewModel ?? {
        email: "",
        emailDirty: false,
    });

    return useCallback(() => {
        hasConfirmed.current = false;

        openDialog(<ResetPasswordDialog initialViewModel={viewModel.current} onConfirm={confirm} onCancel={closeDialog} />);
    }, [openDialog, closeDialog, confirm, viewModel, hasConfirmed]);
};
