import React from "react";

import { Backdrop, BackdropProps, CircularProgress } from "@material-ui/core";
import { createGlobalRefresh } from "../global-refresh.hook";

const { useGlobalRefresh, refresh } = createGlobalRefresh();

let blocking = false;

export const BlockingProgress = (props: Omit<BackdropProps, "children" | "open">) => {
    useGlobalRefresh();

    const { style, ...otherProps } = props;

    return (
        <Backdrop open={blocking} style={{ zIndex: 0, ...style }} {...otherProps}>
            <CircularProgress color="secondary" />
        </Backdrop>
    );
};

export const block = () => {
    blocking = true;
    refresh();
};

export const unblock = () => {
    blocking = false;
    refresh();
};
