export type Validation = {
    severity: "error" | "warning";
    message: string;
} | undefined;

export const validateMandatory = (value: any) => {
    if ( !value ) return "Skal udfyldes";

    return undefined;
};

export const validateEmail = (value: string) => {
    if ( !value ) return undefined;

    if ( !value.match(/\S+@\S+\.\S+/) ) return "Skal være en gyldig email";

    return undefined;
};
