export const defaultTranslation = {
    "validation-error-must-be-specified": "Skal udfyldes",
    "validation-error-must-be-a-valid-email": "Skal være en gyldig email",
    "validation-error-must-be-atleast-six-characters": "Skal være mindst 6 tegn",

    "login-page-welcome-back-heading": "Velkommen tilbage!",
    "login-page-email-label": "Email",
    "login-page-password-label": "Adgangskode",
    "login-page-log-in-button": "Log ind",
    "login-page-reset-password-link": "Har du glemt din adgangskode?",
    "login-page-wrong-password-error": "Adgangskoden er forkert eller brugeren eksisterer ikke.",
    "login-page-generic-error": "Der skete en fejl. Prøv igen.",
};
