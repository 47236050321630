import "core-js/stable";
import "cross-fetch/polyfill";

import React from "react";
import ReactDOM from "react-dom";

import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";

import "./firebase";
import { Application } from "./Application";
import { theme } from "./theme";
import { BlockingProgress } from "./components/BlockingProgress";
import { Snackbar } from "./components/Snackbar";
import { DialogProvider } from "./components/dialog";

ReactDOM.render(
    <React.StrictMode>

        <ThemeProvider theme={theme}>
            <CssBaseline />

            <DialogProvider>
                <Application />
            </DialogProvider>

            <BlockingProgress />
            <Snackbar />
        </ThemeProvider>

    </React.StrictMode>,
    document.getElementById("root")
);
