import React from "react";

import { Snackbar as MaterialSnackbar } from "@material-ui/core";
import { Alert, AlertProps } from "@material-ui/lab";

import { createGlobalRefresh } from "../global-refresh.hook";

const { useGlobalRefresh, refresh } = createGlobalRefresh();
let alert: JSX.Element | undefined;

export const Snackbar = () => {
    useGlobalRefresh();

    return (
        <MaterialSnackbar open={Boolean(alert)}>
            {alert}
        </MaterialSnackbar>
    );
}

export const openSnackbar = (severity: AlertProps["severity"], message: string) => {
    alert = <Alert elevation={6} variant="filled" severity={severity} onClose={closeSnackbar} >{message}</Alert>;
    refresh();
};

export const closeSnackbar = () => {
    alert = undefined;
    refresh();
};
