import React, { useCallback } from "react";

import { OutlinedInput, OutlinedInputProps, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        fontSize: "16px",
        fontWeight: 500,
        backgroundColor: "#FFFFFF",
        color: "#4A4A4A",
        borderRadius: "24px",

        "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.secondary.main,
        },
        "&.Mui-focused:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.primary.main,
        },
        "&.Mui-error:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.error.main,
        },
    },
    input: {
        padding: "12px 16px",
    },
    notchedOutline: {
        marginTop: "5px",

        "& legend": {
            display: "none",
        },
    }
}));

type InputFieldProps = Omit<OutlinedInputProps, "onChange"> & { onChange?: (value: string) => void };

const InputField = (props: InputFieldProps) => {
    const { classes, onChange, ...otherProps } = props;

    const customClasses = useStyles();

    const change = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        if ( !onChange ) return;

        onChange(event.target.value);
    }, [onChange]);

    return (
        <OutlinedInput
            classes={{
                root: customClasses.root,
                input: customClasses.input,
                notchedOutline: customClasses.notchedOutline,
                ...classes,
            }}
            onChange={onChange !== undefined ? change : undefined}
            {...otherProps}
        />
    );
};

export const EmailField = (props: Omit<InputFieldProps, "type">) => {
    return <InputField type="email" {...props} />;
};

export const PasswordField = (props: Omit<InputFieldProps, "type">) => {
    return <InputField type="password" {...props} />;
};
