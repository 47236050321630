import { useCallback, useState } from "react";

import { defaultTranslation } from "./default.translation";
import { englishTranslation } from "./english.translation";

export const useTranslation = () => {
    const [language] = useState<"danish" | "english">(() => {
        if ( window.location.host === "login.gogogarden.com" ) return "english";

        return "danish";
    });

    const t = useCallback((key: keyof typeof defaultTranslation) => {
        if ( language === "english" ) return englishTranslation[key];

        return defaultTranslation[key];
    }, [language]);

    return { t, language };
};
