import React, { useCallback, useMemo, useState } from "react";

import { Button as MaterialButton, Typography, makeStyles } from "@material-ui/core";
import * as firebase from "firebase/app";

import { Label } from "../components/Label";
import { EmailField, PasswordField } from "../components/form/InputField";
import { Button } from "../components/Button";
import { block, unblock } from "../components/BlockingProgress";
import { openSnackbar } from "../components/Snackbar";
import { Group } from "../components/form/Group";
import { Hint } from "../components/form/Hint";
import { UserDocument } from "../firebase.model";
import { useTranslation } from "../internationalisation/translation.hook";
import { ResetPasswordDialogProps, useResetPasswordDialog } from "./ResetPasswordDialog";

const login = firebase.app().functions("europe-west3").httpsCallable("login");
const resetPassword = firebase.app().functions("europe-west3").httpsCallable("User-changePassword");

const useStyles = makeStyles(theme => ({
    smallMargin: {
        marginBottom: theme.spacing(1),
    },
    mediumMargin: {
        marginBottom: theme.spacing(5),
    },
    largeMargin: {
        marginBottom: theme.spacing(6),

        [theme.breakpoints.up(600)]: {
            marginBottom: theme.spacing(9),
        },
    },
    forgotPasswordLink: {
        display: "block",
        padding: 0,
        margin: "0 auto",

        fontSize: "16px",
        fontWeight: 500,
        color: "#4A4A4A",
        textAlign: "center",
        textDecoration: "none",
        textTransform: "none",

        "&:hover": {
            background: "none",
            textDecoration: "underline",
        },
    },
}));

export const LoginPage = () => {
    const classes = useStyles();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [dirty, setDirty] = useState({ email: false, password: false });

    const markEmailDirty = useCallback(() => {
        setDirty({ ...dirty, email: true });
    }, [dirty]);

    const markPasswordDirty = useCallback(() => {
        setDirty({ ...dirty, password: true });
    }, [dirty]);

    const { t, language } = useTranslation();

    const validationErrors = useMemo(() => {
        return {
            email: (() => {
                if ( !email ) return t("validation-error-must-be-specified");
                if ( !email.match(/\S+@\S+\.\S+/) ) return t("validation-error-must-be-a-valid-email");

                return undefined;
            })(),
            password: (() => {
                if ( !password ) return t("validation-error-must-be-specified");
                if ( password.length < 6 ) return t("validation-error-must-be-atleast-six-characters");

                return undefined;
            })(),
        }
    }, [t, email, password]);

    const query = new URLSearchParams(window.location.search);
    const returnUrl = query.get("returnUrl");

    const logIn = useCallback((event: React.MouseEvent) => {
        event.preventDefault();

        if ( document.activeElement )
            (document.activeElement as HTMLInputElement).blur();

        if ( validationErrors.email || validationErrors.password )
            return setDirty({ email: true, password: true });

        block();

        firebase.auth().signInWithEmailAndPassword(email, password).then(async auth => {
            if ( !auth.user ) throw new Error("User not available");

            const idToken = await auth.user.getIdToken();
            const loginResult = await login(idToken);

            const userDocument = await firebase.firestore().collection("users").doc(auth.user.uid).get();
            const user = userDocument.exists ? { id: userDocument.id, ...userDocument.data() } as UserDocument : null;
            const type = user?.type || "gardener";

            const parsedUrl = new URL((() => {
                if ( returnUrl ) return returnUrl;

                switch ( type ) {
                    case "gardener": return language === "english" ? "https://web.gogogarden.com" : "https://web.gogogarden.dk";
                    case "administrator": return "https://admin.gogogarden.dk";
                }
            })());

            const allowedDomains = (() => {
                switch ( type ) {
                    case "gardener": return ["localhost", "gardener-cms.web.app", "web.gogogarden.dk", "web.gogogarden.com"];
                    case "administrator": return ["localhost", "gogo-admin.web.app", "admin.gogogarden.dk"];
                }
            })();

            if ( !allowedDomains.includes(parsedUrl.hostname) ) throw new Error("Unexpected host");

            const url = `${parsedUrl.origin}/login?token=${loginResult.data}`;

            window.location.replace(returnUrl ? `${url}&returnUrl=${returnUrl}` : url);
        })
            .catch(error => {
                console.error(error.message);

                unblock();

                const message = (() => {
                    if ( error.code === "auth/wrong-password" ) return t("login-page-wrong-password-error");

                    return t("login-page-generic-error");
                })();

                openSnackbar("error", message);
            });

    }, [t, validationErrors, email, password, returnUrl, language]);

    const emailError = dirty.email && validationErrors.email;
    const passwordError = dirty.password && validationErrors.password;

    const resetPasswordAdapter = useCallback<ResetPasswordDialogProps["onConfirm"]>((viewModel) => {
        block();

        resetPassword({ email: viewModel.email })
            .then(() => {
                openSnackbar("success", "Vi har sendt et link til nulstilling af adgangskode")
                unblock();
            })
            .catch(() => {
                openSnackbar("error", "Der skete en fejl. Vi kunne ikke sende et link til nulstilling af adgangskode.");
                unblock();
            })
    }, []);
    const openResetPasswordDialog = useResetPasswordDialog(resetPasswordAdapter, {
        email: "",
        emailDirty: false,
    });

    return (
        <main style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "16px" }}>
            <form style={{ maxWidth: "520px", padding: "8px" }}>
                <Typography color="secondary" className={classes.largeMargin} style={{ fontSize: "30px", fontWeight: 600, textAlign: "center" }}>{t("login-page-welcome-back-heading")}</Typography>

                <Label htmlFor="email" className={classes.smallMargin}>{t("login-page-email-label")}</Label>

                <Group className={classes.mediumMargin} error={Boolean(emailError)}>
                    <EmailField id="email" value={email} onChange={setEmail} onBlur={markEmailDirty} />
                    {emailError ? <Hint>{emailError}</Hint> : null}
                </Group>

                <Label htmlFor="password" className={classes.smallMargin}>{t("login-page-password-label")}</Label>

                <Group className={classes.largeMargin} error={Boolean(passwordError)}>
                    <PasswordField id="password" value={password} onChange={setPassword} onBlur={markPasswordDirty} />
                    {passwordError ? <Hint>{passwordError}</Hint> : null}
                </Group>

                <Button type="submit" onClick={logIn} className={classes.mediumMargin}>{t("login-page-log-in-button")}</Button>

                <MaterialButton className={classes.forgotPasswordLink} onClick={openResetPasswordDialog} disableRipple>{t("login-page-reset-password-link")}</MaterialButton>
            </form>
        </main>
    );
};
