import { createTheme } from "@material-ui/core/styles";

export const theme = createTheme({
    palette: {
        background: {
            default: "#EBF3EA",
        },
        primary: {
            main: "#2FA52B",
        },
        secondary: {
            main: "#1C5B13",
        }
    },
    typography: {
        htmlFontSize: 16,
        fontSize: 16,
        fontFamily: "Montserrat, sans-serif",
    },
});
