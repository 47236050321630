import React from "react";

import { Button as MaterialButton, ButtonProps, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    root: {
        display: "block",
        margin: "0 auto",
        borderRadius: "24px",
        textTransform: "none",
        padding: "6px 40px",
    },
});

export const Button = (props: Omit<ButtonProps, "variant">) => {
    const { className, ...otherProps } = props;

    const classes = useStyles();

    return (
        <MaterialButton
            variant="contained"
            color="primary"
            className={className ? `${classes.root} ${className}` : classes.root}
            {...otherProps}
        />
    );
};
