import { Dispatch, SetStateAction, useEffect, useState } from "react";

export const createGlobalRefresh = () => {
    const listeners = new Set<Dispatch<SetStateAction<{}>>>();

    const useGlobalRefresh = () => {
        const [_, setState] = useState({}); // eslint-disable-line @typescript-eslint/no-unused-vars
    
        useEffect(() => {
            listeners.add(setState);
    
            return () => {
                listeners.delete(setState);
            };
        }, []);
    };

    const refresh = () => {
        listeners.forEach(listen => listen({}));
    };

    return { useGlobalRefresh, refresh };
}
