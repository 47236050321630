import { defaultTranslation } from "./default.translation";

export const englishTranslation: typeof defaultTranslation = {
    "validation-error-must-be-specified": "Must be specified",
    "validation-error-must-be-a-valid-email": "Must be a valid email",
    "validation-error-must-be-atleast-six-characters": "Must be atleast 6 characters",

    "login-page-welcome-back-heading": "Welcome back!",
    "login-page-email-label": "Email",
    "login-page-password-label": "Password",
    "login-page-log-in-button": "Log in",
    "login-page-reset-password-link": "Did you forget your password?",
    "login-page-wrong-password-error": "The password is wrong or the user does not exist.",
    "login-page-generic-error": "An error occurred. Try again.",
};
